import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";
// import { MarketingManagerJsonLd } from "../components/jsonld/MarketingManagerJsonLd";

const OutreachcommunityPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    {/* <MarketingManagerJsonLd /> */}

    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                Outreach & Community Manager
                <br />
                Full-Time - 100% Remote
              </h1>
              <div className="lead">
                <p>
                  We're looking for a flexible and versatile outreach and community manager who will
                  be responsible for the growth of our brand, community and social media presence.
                </p>
                <p>
                  To be successful in this role, you should have hands-on
                  experience with outreach strategies and be able to turn
                  creative ideas into effective PR projects.
                  Ultimately, you will help us build and maintain a strong community and
                  social media presence.
                </p>
              </div>

              <p className="lead mb-2">
                <strong>Responsibilities:</strong>
              </p>
              <ul className="lead mb-5">
                <li>
                  Maintaining activity in social media and communities such as Twitter, Reddit, Quora, Discord, etc.
                </li>
                <li>
                  Guest posting outreach and contact management;
                </li>
                <li>
                  Linkbuilding outreach;
                </li>
                <li>
                  Helping with repurposing existing content based on the platforms we work with;
                </li>
                <li>
                  Organizing and taking ownership over repurposing of the existing content;
                </li>
                <li>
                  Monitoring and tracking progress across outreach and engagement channels;
                </li>
                <li>
                  Content promotion across the variety of channels;
                </li>
                <li>
                  Being creative and looking for things to help our brand stand out.
                </li>
              </ul>

              <p className="lead mb-2">
                <strong>Requirements:</strong>
              </p>
              <ul className="lead mb-5">
                <li>
                  Proven experience in managing outreach campaigns;
                </li>
                <li>
                  Solid knowledge of building and growing a community
                </li>
                <li>
                  A sense of aesthetics and a love for great copy and witty communication
                </li>
                <li>
                  Up-to-date with the latest trends and best practices in social media marketing
                </li>
              </ul>

              <p className="lead mb-2">
                <strong>Benefits:</strong>
              </p>
              <ul className="lead mb-5">
                <li className="mb-2">Working remotely (forever);</li>
                <li className="mb-2">No micromanagement;</li>
                <li className="mb-2">Direct Collaboration with the Marketing, Sales and Design teams;</li>
                <li className="mb-2">Managing own campaigns and freedom to experiment;</li>
                <li className="mb-2">Talent Development program to grow professionally &ndash; You will not be stuck in your position or salary.</li>
              </ul>
            </div>

            
          </div>
        </div>
      </section>
    </div>

   
  </Layout>
);

export default OutreachcommunityPage;

export const query = graphql`
  query outreachcommunityPage {
    datoCmsPage(slug: { eq: "outreach-community-manager" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
